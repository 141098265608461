// libraries
import React, { useContext } from "react";
import { Tooltip } from "react-tooltip";

// styling
import "bootstrap/dist/css/bootstrap.min.css";

// component
import { ContextTheme } from "../../../Context/ThemeContext";

const colors = [
  "#FFC72C",
  "#D2122E",
  "#002D62",
  "#32de84",
  "#4B0082",
  "#4cd137",
  "#cd84f1",
  "#3d3d3d",
  "#7cf49a",
  // "#000",
  // "#8cbf17"
];

// ----------  MAIN COMPONENT -----------------------
// id & project_id both are same.
const EmployeesCurrentWorkModal = ({ employee }) => {
  const { toggleTheme } = useContext(ContextTheme);
  const { id, username, currentWork } = employee;

  return (
    <div>
      {/* Button trigger modal started */}
      <Tooltip
        id="employees-list-tooltip"
        style={{
          background: "#111",
          opacity: ".9",
          color: "#fff",
        }}
      />
      <i
        data-tooltip-id="employees-list-tooltip"
        data-tooltip-content={`Check ${username}'s old current work?`}
        data-tooltip-place="top"
        className={`fa-solid fa-circle-plus plus-symbol ${
          toggleTheme ? "dark" : ""
        }`}
        data-bs-toggle={`modal`}
        data-bs-target={`#exampleModal-${id}`}
        style={{ fontSize: "18px", cursor: "pointer" }}
      ></i>

      {/* <!-- Modal --> */}
      <div
        className={`modal fade`}
        id={`exampleModal-${id}`}
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg assign-project-users-modal-dialog">
          <div className="modal-content assign-project-users-modal-content">
            <div className="modal-header assign-project-users-modal-header">
              <h1
                className="modal-title fs-5 default-text-color"
                id="exampleModalLabel"
              >
                {username}'s previous work
              </h1>
              <button
                type="button"
                id="close__assign-project__modal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div
              className="modal-body default-text-color"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="table__section">
                <table className="time-entry-modal-table">
                  <tbody className="time-entry-modal-body assigned-projects-page">
                    {currentWork.map((work) => (
                      <tr
                        key={work.id}
                        className="w-100 time-entry-record time-entries-browser-time-entry-record"
                        style={{
                          borderLeft: `5px solid ${
                            colors[Math.floor(Math.random() * colors.length)]
                          }`,
                        }}
                      >
                        <td
                          style={{
                            padding: "0px 0px 5px 0px",
                            border: "0",
                            fontWeight: 600,
                          }}
                          className="time-entry-modal-project-name default-text-color"
                        >
                          {work.task_short_description}
                        </td>
                        <td
                          style={{ padding: "0", border: "0" }}
                          className="time-entry-modal-project-time default-text-color"
                        >
                          <span>
                            Date:-{" "}
                            {(() => {
                              const date = new Date(work.insert_date);
                              return date.toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              });
                            })()}
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            Time:- {work.formattedTime}
                          </span>
                        </td>
                        <td
                          style={{ padding: "0", border: "0" }}
                          className="time-entry-modal-project-description default-text-color"
                        >
                          Description:- {work.task_long_description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesCurrentWorkModal;
