// libraries
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { ThemeContext, ContextTheme } from "./Context/ThemeContext";
import { AllProjectsContext } from "./Context/AllProjectsContext";
import { UserContext } from "./Context/UserContext";

// stylesheet
import "./stylesheet/index.css";

export { ContextTheme };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeContext /*passing theme*/>
        <UserContext /*passing mainURL*/>
          <AllProjectsContext /*passing all projects data*/>
            <App />
          </AllProjectsContext>
        </UserContext>
      </ThemeContext>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
