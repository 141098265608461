
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "antd";


const MyVerticallyCenteredModal = (props) => {
  const { show, onHide, endTask } = props;
 return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center "></div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p className="d-flex justify-content-center align-items-center" style={
            {fontSize:"20px",fontWeight:"500"}
        }>  Are you sure you want to end this task?</p>
      
        <div className="d-flex justify-content-center gap-3 mt-3">
          <div>
            <button onClick={onHide} className="btn default-button what-are-you-working-on-button btn-primary">No</button>
          </div>
          <div>
            <button onClick={endTask} className="btn default-button what-are-you-working-on-button btn-primary">Yes</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const EndTaskModel = ({ endTask }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
        className=""
        style={{ position: "relative" }}
      >
        <button className="btn default-button what-are-you-working-on-button btn btn-primary">
          End Task
        </button>
      </div>

      <MyVerticallyCenteredModal
        endTask={endTask}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </>
  );
};
