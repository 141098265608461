export const formatTime = (time1, time2) => {
  // Format the time in hh:mm AM/PM format
  const dateObj = new Date(`${time1} ${time2}`);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
    .toString()
    .padStart(2, "0")} ${hours < 12 ? "AM" : "PM"}`;

  return formattedTime;
};
