import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ContextTheme } from "../../../Context/ThemeContext";
import { getProjects } from "../Utils/getProjects";
import { ContextAllProject } from "../../../Context/AllProjectsContext";

export const DashboardSummaryHeader = () => {
  const { toggleTheme } = useContext(ContextTheme);
  const { allProjectList } = useContext(ContextAllProject);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="dashboard__summary-parent__section">
      <div className={`default-section-block ${toggleTheme ? "dark" : ""}`}>
        <span
          className={`dashboard__summary-title ${toggleTheme ? "dark" : ""}`}
        >
          Project Summary
        </span>
        <div className="dashboard__summary-block-parent">
          <div
            className={`dashboard__summary-block ${
              toggleTheme
                ? "dashboard__summary-dark-theme-hover"
                : "dashboard__summary-block__default-hover"
            } all-projects-block`}
          >
            <div className="summary-block__icon">
              <i className="fas fa-clipboard-list dashboard-summary__icon"></i>
            </div>
            <div className="summary-block-content">
              <div
                className={`summary-block-content__title ${
                  toggleTheme ? "dark" : ""
                }`}
              >
                Active Projects
              </div>
              <div
                className={`summary-block-count ${toggleTheme ? "dark" : ""}`}
              >
                <span className="summary-block-count__number">
                  {getProjects(allProjectList, "Running").length}
                </span>
                <p
                  className={`summary-block-count__link ${
                    toggleTheme ? "dark" : ""
                  }`}
                  onClick={() =>
                    navigate("/projects/existing-projects", {
                      state: {
                        status: "active",
                        location,
                      },
                    })
                  }
                >
                  view all
                </p>
              </div>
            </div>
          </div>
          <div
            className={`dashboard__summary-block ${
              toggleTheme
                ? "dashboard__summary-dark-theme-hover"
                : "dashboard__summary-block__default-hover"
            } all-projects-block`}
          >
            <div className="summary-block__icon">
              <i className="fas fa-exclamation-circle file-icon dashboard-summary__icon"></i>
            </div>
            <div className="summary-block-content">
              <div
                className={`summary-block-content__title ${
                  toggleTheme ? "dark" : ""
                }`}
              >
                On Hold Projects
              </div>
              <div
                className={`summary-block-count ${toggleTheme ? "dark" : ""}`}
              >
                <span className="summary-block-count__number">
                  {getProjects(allProjectList, "Hold").length}
                </span>
                <p
                  className={`summary-block-count__link ${
                    toggleTheme ? "dark" : ""
                  }`}
                  onClick={() =>
                    navigate("/projects/existing-projects", {
                      state: {
                        status: "halt",
                        location,
                      },
                    })
                  }
                >
                  view all
                </p>
              </div>
            </div>
          </div>
          <div
            className={`dashboard__summary-block ${
              toggleTheme
                ? "dashboard__summary-dark-theme-hover"
                : "dashboard__summary-block__default-hover"
            } all-projects-block`}
          >
            <div className="summary-block__icon">
              <i className="fas fa-tasks dashboard-summary__icon"></i>
            </div>
            <div className="summary-block-content">
              <div
                className={`summary-block-content__title ${
                  toggleTheme ? "dark" : ""
                }`}
              >
                Completed Projects
              </div>
              <div
                className={`summary-block-count ${toggleTheme ? "dark" : ""}`}
              >
                <span className="summary-block-count__number">
                  {getProjects(allProjectList, "Completed").length}
                </span>
                <p
                  className={`summary-block-count__link ${
                    toggleTheme ? "dark" : ""
                  }`}
                  onClick={() =>
                    navigate("/projects/existing-projects", {
                      state: {
                        status: "completed",
                        location,
                      },
                    })
                  }
                >
                  view all
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
