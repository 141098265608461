import React, { createContext, useEffect, useState, useContext } from "react";

import { ContextUser } from "./UserContext";
import ReactToastify from "../Component/Utility/ReactToastify";

export const ContextAllProject = createContext();

export const AllProjectsContext = ({ children }) => {
  const [LoginToken, setLoginToken] = useState("");
  const { mainUrl } = useContext(ContextUser);
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");

  // states
  const [projectsLoading, setprojectsLoading] = useState(false);
  const [allProjectList, setAllProjectList] = useState([]);

  // setting login token from local storage
  const settingLoginToken = () =>
    setLoginToken(localStorage.getItem("LoginToken"));

  // calling settingLoginToken to set the Login Token
  useEffect(() => {
    settingLoginToken();
  }, []);

  // API call to get all the existing projects (all projects info)
  const getAllExistingProjects = () => {
    if (LoginToken) {
      setprojectsLoading(() => true);
      const url = `${mainUrl}/project`; // used to get all the existing projects

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: newBearer,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
          } else {
            const projects = result.data;
            setAllProjectList(() => projects);
          }
          setprojectsLoading(() => false);
        })
        .catch((error) => {
          setprojectsLoading(() => false);
          ReactToastify(error, "error");
        });
    }
  };

  // calling getAllExistingProjects once Login Token is available
  useEffect(() => {
    getAllExistingProjects();
  }, [LoginToken]);

  return (
    <ContextAllProject.Provider
      value={{
        allProjectList,
        projectsLoading,
        getAllExistingProjects,
        settingLoginToken,
      }}
    >
      {children}
    </ContextAllProject.Provider>
  );
};
