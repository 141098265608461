import { createContext, useEffect, useState } from "react";

export const ContextTheme = createContext();

export const ThemeContext = ({ children }) => {
  const [toggleTheme, setToggleTheme] = useState(false);

  // if localstorage does not have theme variable, initialise the Theme variable with False
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", false);
  }

  // if localstorage has theme ==== true then setting toggleTheme to true otherwise false
  useEffect(() => {
    if (localStorage.getItem("theme") === "true") {
      setToggleTheme(true);
    } else {
      setToggleTheme(false);
    }
  }, []);

  // handling theme
  const handleTheme = () => {
    const tempTheme = localStorage.getItem("theme");

    if (tempTheme === "false") {
      setToggleTheme(true);
      localStorage.setItem("theme", true);
    } else {
      setToggleTheme(false);
      localStorage.setItem("theme", false);
    }
  };

  return (
    <ContextTheme.Provider value={{ handleTheme, toggleTheme }}>
      {children}
    </ContextTheme.Provider>
  );
};
