// function used to bifercate the project (running, onHold, Completed)
export const getProjects = (projectList, status) => {
  return projectList
    ?.reduce((acc, curr) => {
      if (curr.project_status === status) {
        acc = [...acc, curr];
      }
      return acc;
    }, [])
    .reverse();
};
