// libraries
import React, { useState, useContext, useEffect, useMemo } from "react";
import { TailSpin } from "react-loader-spinner";
import { DatePicker } from "antd";

// using react table
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

//components
import Navbar from "../Navbar/Navbar";
import { PageHeader } from "../Utility/PageHeader";
import { PageFooter } from "../Utility/PageFooter";
import { ContextUser } from "../../Context/UserContext";
import ReactToastify from "../Utility/ReactToastify";
import { ContextTheme } from "../../Context/ThemeContext";
import { Button } from "react-bootstrap";
import { CommonGlobalFilter } from "../GlobalFilter/CommonGlobalFilter";
import { PaginationElements } from "../Utility/PaginationElements";

import "../../stylesheet/Pages/AddHoliday.css";

export const AddHoliday = ({ isOpen, setIsOpen }) => {
  const { mainUrl } = useContext(ContextUser);
  const { toggleTheme } = useContext(ContextTheme);

  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("userId"); // logged in userId
  const newUserId = userId.replace(/['"]+/g, "");

  const LoginToken = localStorage.getItem("LoginToken");
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");

  const [holidayDate, setHolidayDate] = useState("");
  const [holidayName, setHolidayName] = useState("");

  const [holidayData, setHolidayData] = useState([]);

  const onChange = (date, dateString) => {
    setHolidayDate(dateString);
  };

  const handleClear = () => {
    setHolidayDate("");
    setHolidayName("");
  };

  // getting all the holidays
  const getHolidaysList = () => {
    const url = `${mainUrl}/holiday-list`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: newBearer,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const data = result.data.filter((holiday) => holiday.id);
        setHolidayData(data);
      });
  };

  useEffect(() => {
    getHolidaysList();
  }, []);

  // Function For adding New Holiday
  const addNewHoliday = () => {
    setLoading(true);
    const url = `${mainUrl}/add-new-holiday`;
    const requestOptions = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: newBearer,
    };
    const data = {
      current_user: newUserId,
      holiday_name: holidayName,
      holiday_date: holidayDate,
    };

    fetch(url, {
      method: "POST",
      headers: requestOptions,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          if (typeof result.message == "object") {
            const value = Object.values(result.message);
            ReactToastify(value[0], "error");
          } else {
            ReactToastify(result.message, "error");
          }
        } else {
          handleClear();
          ReactToastify(result.message, "success");
          getHolidaysList();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        ReactToastify(error, "error");
      });
  };

  // on click of Submit button
  const handleSubmit = (e) => {
    e.preventDefault();
    addNewHoliday();
  };

  // React Table implementation
  const Columns = [
    {
      Header: "Holiday Name",
      accessor: "holiday_name",
    },

    {
      Header: "Date",
      accessor: "date",
    },
  ];

  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => holidayData, [holidayData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    canNextPage,
    prepareRow,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      {/* Navbar */}
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Header */}
      <PageHeader />
      <div
        className={`default__page__margin default-bg-color ${
          toggleTheme ? "dark" : ""
        }`}
      >
        <div className="add-holiday-wrapper mt-2">
          <div
            className={`default-section-block ${
              toggleTheme ? "dark" : ""
            } form-content holiday-form-wrapper`}
          >
            <h3
              className={`${
                toggleTheme ? "dark-text-color" : "default-text-color"
              }`}
            >
              Add Holiday
            </h3>
            {/* Update details */}
            {loading ? (
              <TailSpin
                height="50"
                width="50"
                color="#333"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  width: "100%",
                  height: "50vh",
                  top: "10vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass="loader"
                visible={true}
              />
            ) : (
              <div className="form-page form__div-box holiday-form">
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="holiday_name"
                      className={`form-label ${
                        toggleTheme
                          ? "dark-sub-text-color"
                          : "default-text-color"
                      }`}
                    >
                      Holiday Name
                    </label>
                    <input
                      type="text"
                      className="form-control default__input"
                      style={{ margin: "0" }}
                      id="holiday_name"
                      value={holidayName}
                      onChange={(e) => setHolidayName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="holiday_date"
                      className={`form-label ${
                        toggleTheme
                          ? "dark-sub-text-color"
                          : "default-text-color"
                      }`}
                    >
                      Holiday Date
                    </label>
                    <div className="holiday-datepicker-wrapper">
                      <DatePicker
                        className="form-control default__input holiday-datepicker"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <Button onClick={handleSubmit} className="btn default-button">
                    Add Holiday
                  </Button>
                </form>
              </div>
            )}
          </div>
          {/* React table */}
          <div className={`default-section-block ${toggleTheme ? "dark" : ""}`}>
            <div className="holiday-list-wrapper">
              <h3
                className={`${
                  toggleTheme ? "dark-text-color" : "default-text-color"
                }`}
              >
                Holiday List
              </h3>
              {holidayData?.length > 0 ? (
                <>
                  <CommonGlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                  <table
                    {...getTableProps()}
                    className="table text-center default__table__content holiday-list-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className={`default-projects-table__heading ${
                            toggleTheme
                              ? "dark-text-color"
                              : "default-text-color"
                          }`}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column) => {
                            return (
                              <th
                                className="default-projects-table__heading-headers"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <span>
                                  <span>{column.render("Header")}</span>
                                  <span className="table-heading-span-styling-arrow">
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <span>↓</span>
                                      ) : (
                                        <span>↑</span>
                                      )
                                    ) : (
                                      <span>⇅</span>
                                    )}
                                  </span>
                                </span>
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr
                            className={`default-projects-table__values ${
                              toggleTheme
                                ? "dark-sub-text-color"
                                : "default-text-color"
                            }`}
                            {...row.getRowProps()}
                            key={index}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="existing-projects-table-body-rows-td"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {holidayData && holidayData.length > 10 ? (
                    <PaginationElements
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      canPreviousPage={canPreviousPage}
                      previousPage={previousPage}
                      canNextPage={canNextPage}
                      nextPage={nextPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <h5 className="mt-3">No Data Found</h5>
              )}
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
};
