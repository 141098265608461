import React from "react";

export const PageSizeDropdown = ({ setPageSize, pageSize }) => {
  return (
    <select
      className="react-table-pageSize-dropdown"
      value={pageSize}
      onChange={(e) => setPageSize(Number(e.target.value))}
    >
      {[10, 25, 50, 100].map((elem) => (
        <option
          className="react-table-pageSize-dropdown-options"
          key={elem}
          value={elem}
        >
          Show {elem} rows
        </option>
      ))}
    </select>
  );
};
