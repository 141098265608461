import React, { useEffect, useContext, useState } from "react";
import { Button } from "react-bootstrap";

import { ContextTheme } from "../../../Context/ThemeContext";
import ReactToastify from "../../Utility/ReactToastify";

export const DashboardTodoList = () => {
  const { toggleTheme } = useContext(ContextTheme);

  // FOR TODO LIST start
  const [allTodos, setAllTodos] = useState([]);
  const [allTodosFromLocalStorage, setAllTodosFromLocalStorage] = useState([]);

  const [addNewTodo, setAddNewTodo] = useState({
    id: allTodos.length > 0 ? Number(allTodos[allTodos.length - 1].id) + 1 : 1,
    todo_name: "",
    completed: false,
  });

  if (!localStorage.getItem("allTodos")) {
    localStorage.setItem("allTodos", JSON.stringify(allTodos));
  }

  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem("allTodos"));
    if (temp.length > 0) {
      setAllTodos(temp);
      setAddNewTodo((prev) => ({
        ...prev,
        id: Number(temp[temp.length - 1].id) + 1,
      }));
    }
  }, []);

  // fetching todo list from localstorage
  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem("allTodos"));
    setAllTodosFromLocalStorage(temp);
  }, [allTodos]);

  // helper function if todo exists
  const todoExists = () => {
    // setting localstorage
    const temp = JSON.parse(localStorage.getItem("allTodos"));
    const newTodos = temp?.map((singleTodo) =>
      singleTodo.id === addNewTodo.id
        ? { ...addNewTodo, completed: false }
        : singleTodo
    );
    localStorage.setItem("allTodos", JSON.stringify(newTodos));

    const updatedTodo = allTodos.map((singleTodo) =>
      singleTodo.id === addNewTodo.id
        ? { ...addNewTodo, completed: false }
        : singleTodo
    );
    setAllTodos(updatedTodo);

    setAddNewTodo((prev) => ({ ...prev, id: "", todo_name: "" })); // resetting newTodo State
  };

  // helper function if adding new todo
  const newTodo = () => {
    // setting localstorage
    const temp = JSON.parse(localStorage.getItem("allTodos")); // fetching from localstorage
    temp.push({
      ...addNewTodo,
      id: temp.length > 0 ? Number(temp[temp.length - 1].id) + 1 : 1,
    });
    localStorage.setItem("allTodos", JSON.stringify(temp));

    // setting state
    setAllTodos((prev) => [
      ...prev,
      {
        ...addNewTodo,
        id:
          allTodos.length > 0
            ? Number(allTodos[allTodos.length - 1].id) + 1
            : 1,
      },
    ]);

    setAddNewTodo((prev) => ({ ...prev, id: "", todo_name: "" })); // resetting newTodo State
  };

  // Handling Add Todo functionality
  const handleAddTodo = () => {
    if (allTodos.find((todo) => todo.id === addNewTodo.id)) {
      // if todo exists go inside here
      todoExists();
    } else if (addNewTodo.todo_name !== "") {
      // if adding new todo go inside here
      newTodo();
    } else {
      ReactToastify("Please add Todo Name 😐", "error");
    }
  };

  // Helper function for Edit Todo
  const handleEditTodo = (todo) => {
    setAddNewTodo(() => ({
      id: todo.id,
      todo_name: todo.todo_name,
      completed: todo.completed,
    }));
  };
  // FOR TODO LIST End

  return (
    <div
      className={`user-todo-list default-section-block ${
        toggleTheme ? "dark" : ""
      }`}
    >
      <h5
        className={`projects__summary__header ${
          toggleTheme ? "dark" : ""
        } employee__dashboard-title`}
      >
        Todo List <i className="fa-solid fa-check-double"></i>
      </h5>
      <div className="todo-list-header">
        <input
          type="text"
          autoComplete="off"
          value={addNewTodo.todo_name}
          onChange={(e) =>
            setAddNewTodo((prev) => ({
              ...prev,
              todo_name: e.target.value,
              completed: false,
            }))
          }
          className="default__input"
        />
        <Button
          type="button"
          onClick={handleAddTodo}
          className="btn default-button"
        >
          Add
        </Button>
      </div>

      {allTodosFromLocalStorage.map((todo) => {
        return (
          <div key={todo.id} className="todo">
            <div className="todo-content-wrapper">
              <input
                className="todo-checkbox"
                type="checkbox"
                id={`todo-checkbox-${todo.id}`}
                onChange={() => {
                  const temp = allTodosFromLocalStorage.map((singleTodo) =>
                    singleTodo.id === todo.id
                      ? {
                          ...singleTodo,
                          completed: !singleTodo.completed,
                        }
                      : singleTodo
                  );

                  localStorage.setItem("allTodos", JSON.stringify(temp));

                  setAllTodos(temp);
                }}
                checked={todo.completed}
              />
              <label
                htmlFor={`todo-checkbox-${todo.id}`}
                className={`todo-content ${
                  toggleTheme ? "dark-text-color" : "default-text-color"
                }`}
                style={{
                  textDecoration: todo.completed ? "line-through" : "",
                }}
              >
                {todo.todo_name}
              </label>
            </div>
            <div className="todo-call-to-action-wrapper">
              <Button
                onClick={() => handleEditTodo(todo)}
                type="button"
                className="btn default-button"
              >
                <i className="bi bi-pencil-fill"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  const temp = allTodos.filter(
                    (singleTodo) => singleTodo.id !== todo.id
                  );
                  localStorage.setItem("allTodos", JSON.stringify(temp));
                  setAllTodos(temp);
                }}
                className="btn default-button"
              >
                <i className="bi bi-trash"></i>
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
