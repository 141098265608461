export function getTimeDifference(startDateTimeStr) {
  const startTime = new Date(startDateTimeStr);
  const currentTime = new Date();

  const timeDifference = (currentTime - startTime) / 1000; // Convert to seconds

  const hours = Math.floor(timeDifference / 3600);
  const remainingSeconds = timeDifference % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  let timeString = "";
  if (hours >= 1) {
    timeString += hours === 1 ? "1 hour" : `${hours} hours`;
    if (minutes > 0) {
      timeString += ` and ${minutes} mins`;
    }
  } else {
    timeString += `${minutes} mins`;
  }

  return timeString;
}
