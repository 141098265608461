// libraries
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, Link, Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";

// components
import logo from "../../images/neel-networks-logo.svg";
import { ContextUser, UserContext } from "../../Context/UserContext";
import ReactToastify from "./ReactToastify";
import { ContextTheme } from "../../Context/ThemeContext";
import { formatDateTime } from "../Utility/formatDateTime";

// styling
import "../../stylesheet/App.css";
import "../../stylesheet/Utility/PageHeader.css";

export const PageHeader = () => {
  const navigate = useNavigate();
  const { mainUrl } = useContext(ContextUser);
  const { toggleTheme, handleTheme } = useContext(ContextTheme);

  // fetching logged in user login token
  const LoginToken = localStorage.getItem("LoginToken"); // logged in user login token
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");

  // fetching logged in user id
  const userId = localStorage.getItem("userId"); // the logged in user id
  const newUserId = userId.replace(/['"]+/g, "");

  // states
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleSignOut = () => {
    // localStorage.clear();
    localStorage.removeItem("LoginToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    window.location.reload();
    // navigate("/");
  };

  const userName = localStorage.getItem("userName");
  const newUserName = userName.replace(/['"]+/g, "");

  // toggling notifications list
  const handleNotifications = () => {
    setShowNotifications((prev) => !prev);
    updateNotifications();
  };

  // used to get all notifications
  const getNotifications = () => {
    if (LoginToken) {
      const url = `${mainUrl}/user/all-notifications/${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get all notifications for the logged in user
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (!result.error) {
            setNotifications(result.notification.reverse());
          }
        })
        .catch((error) => {
          // ReactToastify(error, "error");
          //console.log(error);
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  // updating notifications (mark as read)
  const updateNotifications = () => {
    if (LoginToken) {
      const url = `${mainUrl}/user/update-notifications/${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      const isReadId = notifications
        .filter((ele) => ele.is_read === "no") //.slice(0, 5)
        .reduce((acc, curr) => `${acc + curr.id},`, "");
      // console.log(isReadId.slice(0, isReadId.length - 1));

      const data = {
        notification_id: isReadId,
      };

      //this fetch call is used to get all notifications for the logged in user
      fetch(url, {
        method: "PATCH",
        headers: requestOptions,
        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          // console.log(result);
        })
        .catch((error) => {
          // ReactToastify(error, "error");
          //console.log(error);
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  };

  const NotificationsModal = () => {
    return (
      <>
        <i
          data-toggle="modal"
          data-target="#notificationsModal"
          className={`fa-solid fa-bell notification-icon ${
            toggleTheme ? "dark" : ""
          }`}
          onClick={handleNotifications}
        >
          {notifications.filter((ele) => ele.is_read === "no").length > 0 ? (
            <span className="all-notifications-count">
              {notifications.filter((ele) => ele.is_read === "no").length}
            </span>
          ) : (
            ""
          )}
        </i>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="notificationsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className={`modal-title`}
                  style={{ color: "#000" }}
                  id="exampleModalLabel"
                >
                  Notifications List
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className={`modal-body notifications_list `}
                style={{ color: "#000" }}
              >
                <div>
                  <div className="notifications-list__content">
                    <ul>
                      {notifications.filter((ele) => ele.is_read === "no")
                        .length <= 0 ? (
                        <p className="zero-notifications-msg">
                          No new notifications
                        </p>
                      ) : (
                        notifications
                          .filter((ele) => ele.is_read === "no")
                          .map((ele) => {
                            return (
                              <li
                                className="notfications-list__li"
                                key={ele.id}
                              >
                                <p className="notfications-list__li-title">
                                  {ele.notification_for}
                                </p>
                                <p className="notfications-list__li-sub-title">
                                  {ele.notification_message}
                                </p>
                                <p className="notfications-list__li-time">
                                  {formatDateTime(ele.created_at)}
                                </p>
                              </li>
                            );
                          })
                      )}
                    </ul>
                    <p
                      className="view-all-notifications-link"
                      onClick={() => navigate("/all-notifications")}
                      data-dismiss="modal"
                    >
                      View All Notifications
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`default__heading ${toggleTheme ? "dark" : ""}`}>
      <NavLink to="/" className="logo">
        <img src={logo} className="pms__logo" alt="Neel Networks Logo" />
      </NavLink>

      <span className="profile">
        <div className="theme-switcher">
          <label className="switch">
            <span className="sun">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g fill="#ffd43b">
                  <circle r="5" cy="12" cx="12"></circle>
                  <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                </g>
              </svg>
            </span>
            <span className="moon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
              </svg>
            </span>
            <input onClick={handleTheme} type="checkbox" className="input" />
            <span className="slider"></span>
          </label>
          <NotificationsModal />
        </div>
        <div className="profile-navbar">
          <span className="user-name">
            {newUserName} <i className="fas fa-sort-down sort-down-icon"></i>
          </span>
          <div className="profile-navbar-dropdown">
            <NavLink to="/my-profile" className="profile-navbar-dropdown-span">
              <span className="profile-link-span">Profile</span>
            </NavLink>
            <button
              className="profile-navbar-dropdown-span logout-button"
              onClick={handleSignOut}
            >
              Logout
            </button>
          </div>
        </div>
      </span>
    </div>
  );
};
