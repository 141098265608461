// libraries
import React, { useEffect, useContext } from "react";

//  css styling
import "../../stylesheet/Dashboard/Dashboard.css";

// importing components
import Navbar from "../Navbar/Navbar";
import { PageHeader } from "../Utility/PageHeader";
import { PageFooter } from "../Utility/PageFooter";
import { ContextTheme } from "../../Context/ThemeContext";
import { DashboardSummaryHeader } from "./DashboardComponents/DashboardSummaryHeader";
import { DashboardProjectsTable } from "./DashboardComponents/DashboardProjectsTable";
import { ContextAllProject } from "../../Context/AllProjectsContext";
import { DashboardEmployeesCurrentWork } from "./DashboardComponents/DashboardEmployeesCurrentWork";
import { DashboardQuickLinks } from "./DashboardComponents/DashboardQuickLinks";
import { DashboardTodoList } from "./DashboardComponents/DashboardTodoList";

export const AdminDashboard = ({ isOpen, setIsOpen }) => {
  // accessing login token from localstorage
  const { toggleTheme } = useContext(ContextTheme);
  const { getAllExistingProjects } = useContext(ContextAllProject);

  // calling getAllExistingProjects to get updated list of projects
  useEffect(() => {
    getAllExistingProjects();
  }, []);

  return (
    <>
      {/* Navbar */}
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Header */}
      <PageHeader />
      {/* Main content */}

      <div
        className={`default__page__margin default-bg-color ${
          toggleTheme ? "dark" : ""
        } ${isOpen ? "open" : ""}`}
      >
        <div
          className={`mt-2 dashboard ${
            toggleTheme ? "dark" : ""
          }  admin-dashboard-page`}
        >
          {/* dashboard parent section (Project Summary section) */}
          <DashboardSummaryHeader />

          {/* Projects Table section (Active & Completed Project table section) */}
          <DashboardProjectsTable />

          {/* Quick links section */}
          <div className="Admin-dashboard__employees-working-on_quick-links">
            {/* Employees current work */}
            <DashboardEmployeesCurrentWork />

            <div className="quick-links-and-todo-list-wrapper">
              {/* Todo list */}
              <DashboardTodoList />

              {/* Quick Links */}
              <DashboardQuickLinks />
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <PageFooter />
    </>
  );
};
