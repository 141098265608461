export const projectCategoryOptions = [
  {
    label: "Standard Website HTML/CSS/PHP",
    value: "Standard Website HTML/CSS/PHP",
  },
  { label: "Website Redesign", value: "Website Redesign" },
  { label: "WordPress CMS Website", value: "WordPress CMS Website" },
  {
    label: "WooCommerce Ecommerce Website",
    value: "WooCommerce Ecommerce Website",
  },
  { label: "Shopify Ecommerce Website", value: "Shopify Ecommerce Website" },
  {
    label: "Other CMS Ecommerce Website",
    value: "Other CMS Ecommerce Website",
  },
  { label: "Custom Website Development", value: "Custom Website Development" },
  { label: "Mobile Application", value: "Mobile Application" },
  { label: "Website Amendments", value: "Website Amendments" },
  {
    label: "Website Speed and Bug Fixing",
    value: "Website Speed and Bug Fixing",
  },
  { label: "Content Writing", value: "Content Writing" },
  { label: "Products Data Entry", value: "Products Data Entry" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Graphic Design & Animation", value: "Graphic Design & Animation" },
  { label: "Logo Design", value: "Logo Design" },
  { label: "UI UX Design", value: "UI UX Design" },
  { label: "Research Documentation", value: "Research Documentation" },
];
