import { createContext } from "react";

export const ContextUser = createContext();

export const UserContext = ({ children }) => {
  // for live purpose & package.json me => "homepage": "https://pms.neelnetworks.in",
  const mainUrl = `https://pms.neelnetworks.in/api`;

  // for Testing purpose & package.json me => "homepage": "https://pms.neelnetworks.org",
  // const mainUrl = `https://ems.neelnetworks.org/v4`;
  // const mainUrl = `https://pms.neelnetworks.in/api`
  // if (process.env.NODE_ENV === 'development') {
  return (
    <ContextUser.Provider value={{ mainUrl }}>{children}</ContextUser.Provider>
  );
};
