import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

import { ContextTheme } from "../../../Context/ThemeContext";
import { getProjects } from "../Utils/getProjects";
import { ContextAllProject } from "../../../Context/AllProjectsContext";

export const DashboardProjectsTable = () => {
  const { toggleTheme } = useContext(ContextTheme);
  const { allProjectList, projectsLoading, settingLoginToken } =
    useContext(ContextAllProject);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    settingLoginToken();
  }, []);

  return (
    <>
      {/* Projects Table section */}
      <div className="dashboard__active-projects__completed-projects">
        {/* Active projects section */}
        <div className={`default-section-block ${toggleTheme ? "dark" : ""}`}>
          <div>
            <h5
              className={`projects__summary__header ${
                toggleTheme ? "dark" : ""
              }`}
            >
              Active Projects <i className="bi bi-gear loop"></i>
            </h5>

            <div>
              {getProjects(allProjectList, "Running").length > 0 ? (
                <>
                  {projectsLoading ? (
                    <TailSpin
                      height="50"
                      width="50"
                      color="#333"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{
                        position: "absolute",
                        width: "40%",
                        height: "15vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      wrapperClass="loader"
                      visible={true}
                    />
                  ) : (
                    <table className="table">
                      <thead>
                        <tr
                          className={`dashboard-projects-table__heading ${
                            toggleTheme ? "dark-text-color" : ""
                          }`}
                        >
                          <th>Project. Id</th>
                          <th>Project Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getProjects(allProjectList, "Running")
                          .slice(0, 10)
                          .reverse()
                          ?.map((project) => {
                            return (
                              <tr
                                className={`dashboard-projects-table__values ${
                                  toggleTheme
                                    ? "dark-sub-text-color"
                                    : "default-text-color"
                                }`}
                                key={project.project_id}
                              >
                                <td>
                                  {project.project_no !== ""
                                    ? project.project_no
                                    : "N/A"}
                                </td>
                                <td title={project.project_name}>
                                  {project.project_name.length > 40
                                    ? `${
                                        project.project_name.slice(0, 40) +
                                        "..."
                                      }`
                                    : project.project_name}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}

                  {getProjects(allProjectList, "Running").length > 10 ? (
                    <p
                      onClick={() =>
                        navigate("/projects/existing-projects", {
                          state: {
                            status: "active",
                            location,
                          },
                        })
                      }
                      className="summary-block-count__link check__all__projects"
                    >
                      view all
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <p>There are 0 projects in active status currently</p>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Completed projects section */}
        <div className={`default-section-block ${toggleTheme ? "dark" : ""}`}>
          <div>
            <h5
              className={`projects__summary__header ${
                toggleTheme ? "dark" : ""
              }`}
            >
              Completed Projects <i className="bi bi-check-circle"></i>
            </h5>

            <div>
              {getProjects(allProjectList, "Completed").length > 0 ? (
                <>
                  <table className="table">
                    <thead>
                      <tr
                        className={`dashboard-projects-table__heading ${
                          toggleTheme ? "dark-text-color" : ""
                        }`}
                      >
                        <th>Project. Id</th>
                        <th>Project Name</th>
                      </tr>
                    </thead>
                    {projectsLoading ? (
                      <TailSpin
                        height="50"
                        width="50"
                        color="#333"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                          position: "absolute",
                          width: "40%",
                          height: "15vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass="loader"
                        visible={true}
                      />
                    ) : (
                      <tbody>
                        {getProjects(allProjectList, "Completed")
                          .slice(0, 10)
                          .reverse()
                          .map((project) => (
                            <tr
                              className={`dashboard-projects-table__values ${
                                toggleTheme
                                  ? "dark-sub-text-color"
                                  : "default-text-color"
                              }`}
                              key={project.project_id}
                            >
                              <td>
                                {project.project_no !== ""
                                  ? project.project_no
                                  : "N/A"}
                              </td>
                              <td title={project.project_name}>
                                {project.project_name.length > 40
                                  ? `${
                                      project.project_name.slice(0, 40) + "..."
                                    }`
                                  : project.project_name}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                  {getProjects(allProjectList, "Completed").length > 10 ? (
                    <p
                      className="summary-block-count__link check__all__projects"
                      onClick={() =>
                        navigate("/projects/existing-projects", {
                          state: {
                            status: "completed",
                            location,
                          },
                        })
                      }
                    >
                      view all
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <p
                  className={`${
                    toggleTheme ? "dark-sub-text-color" : "default-text-color"
                  }`}
                >
                  None of the projects are fully completed yet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
