import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ContextTheme } from "../../../Context/ThemeContext";

export const DashboardQuickLinks = () => {
  const { toggleTheme } = useContext(ContextTheme);
  const userRole = localStorage.getItem("userRole");
  return (
    <div
      className={`default-section-block  ${
        toggleTheme ? "dark" : ""
      } dashboard__quick-links`}
    >
      <h5
        className={`projects__summary__header ${
          toggleTheme ? "dark" : ""
        } employee__dashboard-title`}
      >
        Quick Links <i className="fas fa-link"></i>
      </h5>
      <div className="admin__dashboard-quick-links__blocks">
        {userRole === '"admin"' || userRole === '"manager"' ? (
          <>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/projects/existing-projects"
            >
              Create a Project
            </NavLink>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/projects/assign-project"
            >
              Assign a Project
            </NavLink>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/assign-task"
            >
              Assign a Task
            </NavLink>
          </>
        ) : (
          ""
        )}
        {userRole === '"admin"' ? (
          <>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/users/all/add-new-user"
            >
              Add new User
            </NavLink>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/add-holiday"
            >
              Add holiday
            </NavLink>
          </>
        ) : (
          ""
        )}
        {userRole === '"employee"' ? (
          <>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/projects/add-time-in-project"
            >
              Add Time Entry
            </NavLink>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/assign-task"
            >
              Assign a Task
            </NavLink>
            <NavLink
              className={`quick-links__button ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-quick-link`}
              to="/my-profile"
            >
              My Profile
            </NavLink>
          </>
        ) : (
          ""
        )}
        {/* <NavLink
          className={`quick-links__button ${
            toggleTheme ? "dark" : ""
          } employee__dashboard-quick-link`}
          to="/add-project-category"
        >
          Add Project Category
        </NavLink>
        <NavLink
          className={`quick-links__button ${
            toggleTheme ? "dark" : ""
          } employee__dashboard-quick-link`}
          to="/add-employee-job-role"
        >
          Add Employee Job Role
        </NavLink> */}
      </div>
    </div>
  );
};
