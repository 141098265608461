// libraries
import React, { useEffect, useState, useContext } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Select } from "antd";
import { Tooltip } from "react-tooltip";

//  css styling
import "../../stylesheet/Dashboard/Dashboard.css";

// importing components
import Navbar from "../Navbar/Navbar";
import EmployeeBarChart from "../Charts/EmployeeBarChart";
import { EmployeeDoughnutChart } from "../Charts/EmployeeDoughnutChart";
import { ContextUser } from "../../Context/UserContext";
import ReactToastify from "../Utility/ReactToastify";
import { PageHeader } from "../Utility/PageHeader";
import { PageFooter } from "../Utility/PageFooter";
import { ContextTheme } from "../../Context/ThemeContext";
import { Button } from "react-bootstrap";
import { formatTime } from "../Utility/formatTime";
import { formatTotalWorkTime } from "../Utility/formatTotalWorkTime";
import { getTimeDifference } from "../Utility/calculateTime";
import { DashboardQuickLinks } from "./DashboardComponents/DashboardQuickLinks";
import { DashboardTodoList } from "./DashboardComponents/DashboardTodoList";
import { DashboardSuggestionBox } from "./DashboardComponents/DashboardSuggestionBox";
import { EndTaskModel } from "./DashboardComponents/EndTaskModel";

export const EmployeeDashboard = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainUrl } = useContext(ContextUser);
  const { toggleTheme } = useContext(ContextTheme);

  const LoginToken = localStorage.getItem("LoginToken");
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");
  const userId = localStorage.getItem("userId"); // the logged in user id
  const newUserId = userId.replace(/['"]+/g, "");

  // states
  const [pendingTasks, setPendingTasks] = useState([]);
  const [reviewTasks, setReviewTasks] = useState([]);
  const [allProjectsCount, setAllProjectsCount] = useState("");
  const [completedProjectsCount, setCompletedProjectsCount] = useState("");
  const [runningProjectsCount, setRunningProjectsCount] = useState("");
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  const [employeeProjects, setEmployeeProjects] = useState([]);

  const [employeesData, setEmployeesData] = useState([]);
  const [currentEmployeesTaskList, setCurrentEmployeesTaskList] = useState([]);
  const [showAllEmployeesCurrentWork, setShowAllEmployeesCurrentWork] =
    useState(false);

  const [showCurrentEmployeeWorkList, setShowCurrentEmployeeWorkList] =
    useState(false);

  const [errorWorkMsg, setErrorWorkMsg] = useState("");
  const [loggedInUserCurrentWork, setLoggedInUserCurrentWork] = useState({});
  const [shortWorkMsg, setShortWorkMsg] = useState("");
  const [longWorkMsg, setLongWorkMsg] = useState("");
  const [currentWorkId, setCurrentWorkId] = useState("");

  const [loading, setLoading] = useState(false);
  const [dummyState, setDummyState] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shareSuggestions, setShareSuggestions] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [updateTask, setUpdateTask] = useState({
    taskName: "",
    projectName: "",
  });

  const currentWorkChange = (value) => {
    setShortDescription(value);
  };

  //getting active projects list for the logged in user
  const getProjectsData = () => {
    if (LoginToken) {
      const url = `${mainUrl}/user/assign-project-list-for-single-user/${newUserId}`;
      // used for getting active projects list
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: newBearer,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            setEmployeeProjects([]);
          } else {
            setEmployeeProjects(result.data);
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify("Something went wrong, please login again!", "error");
      // localStorage.clear();
      localStorage.removeItem("LoginToken");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("userId");
    }
  };

  useEffect(() => {
    getProjectsData();
  }, []);

  // to get count for all, completed, active projects of an employee
  useEffect(() => {
    if (LoginToken) {
      const url = `${mainUrl}/user/count-project-list-for-single-user/${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get all projects count of an employee (total projects, completed projects, active projects)
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
          } else {
            setAllProjectsCount(result.count[0].all_project);
            setCompletedProjectsCount(result.count[0].completed);
            setRunningProjectsCount(result.count[0].running);
          }
        })
        .catch((error) => {
          // toast.error(error);
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  }, []);

  // using this code we are getting all the pending tasks of the logged in user
  useEffect(() => {
    if (LoginToken) {
      const url = `${mainUrl}/task/list?task_status=Pending&login_user=${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get all pending tasks
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            setPendingTasks([]);
          } else {
            setPendingTasks(result.data);
          }
        })
        .catch((error) => {
          // toast.error(error);
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  }, []);

  // using this code we are getting all the reviewing tasks of the logged in user
  useEffect(() => {
    if (LoginToken) {
      const url = `${mainUrl}/task/list?task_status=Review&login_user=${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get reviewing tasks data
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            setReviewTasks([]);
          } else {
            setReviewTasks(result.data);
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  }, []);

  // This code is used to display Good Morning, Good Afternoon or evening on with username.
  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const newUserName = userName.replace(/['"]+/g, "");
    setUsername(newUserName);

    const now = new Date();
    const hours = now.getHours();

    if (hours >= 0 && hours < 12) {
      setMessage("Good Morning, ");
    } else if (hours >= 12 && hours < 16) {
      setMessage("Good Afternoon, ");
    } else {
      setMessage("Good Evening, ");
    }
  }, []);

  // this code is used to submit the current work employee is working on
  const handleSubmitWork = (e) => {
    e.preventDefault();

    if (longDescription === "" || shortDescription === "") {
      ReactToastify(
        "Please enter all details to post work current work!",
        "error"
      );
    } else {
      if (isSubmitting) {
        return; // Prevent multiple submissions
      }
      setIsSubmitting(true);

      if (LoginToken) {
        // setLoading(true);
        const url = `${mainUrl}/user/add-current-work`;

        const requestOptions = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: newBearer,
        };

        const data = {
          user_id: newUserId,
          task_short_description: shortDescription,
          task_long_description: longDescription,
        };

        //this fetch call is used to get reviewing tasks data
        fetch(url, {
          method: "POST",
          headers: requestOptions,
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.error) {
              ReactToastify(result.message, "error");
            } else {
              ReactToastify(result.message, "success");
              setLongDescription("");
              setShortDescription("");
              getWorkMsg();
              setDummyState(!dummyState);
              fetchUpdatedTasks();
            }
          })
          .catch((error) => {
            ReactToastify(error, "error");
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        ReactToastify(
          "Sorry you are not authorised, please login again",
          "error"
        );
      }
    }
  };

  const projectOptions = employeeProjects?.map((project) => {
    return {
      label: `Project - ${project.Project_name}`,
      value: `Project - ${project.Project_name}`,
    };
  });

  const taskOptions = pendingTasks.map((task) => {
    return {
      label: `Task - ${task.task_name}`,
      value: `Task - ${task.task_name}`,
    };
  });

  const currentWorkOptions = [
    ...projectOptions,
    ...taskOptions,
    {
      label: `(I'm Free) Learning / Practicing`,
      value: `(I'm Free) Learning / Practicing`,
    },
    {
      label: `Documentation & Research`,
      value: `Documentation & Research`,
    },
    {
      label: `Other Work`,
      value: `Other Work`,
    },
    {
      label: `SEO `,
      value: `SEO `,
    },
    {
      label: `In House Project`,
      value: `In House Project`,
    },
    {
      label: `Unassigned Task`,
      value: `Unassigned Task`,
    },
  ];

  // This API returns what employee is working on currently.
  const getWorkMsg = () => {
    if (LoginToken) {
      setLoading(true);
      const url = `${mainUrl}/user/get-my-current-work/${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get reviewing tasks data
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            setErrorWorkMsg(result.message);
            setLoading(false);
          } else {
            setErrorWorkMsg("");
            setLoggedInUserCurrentWork(result.data[0]);
            setShortWorkMsg(result.data[0].task_short_description);
            setLongWorkMsg(result.data[0].task_long_description);
            setCurrentWorkId(result.data[0].id);
            setLoading(false);
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
          setLoading(false);
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  };
  const fetchUpdatedTasks = () => {
    if (LoginToken) {
      const url = `${mainUrl}/user/get-current-work-by-employee/${newUserId}`;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: newBearer,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
            return;
          }

          const currentUserId = Number(newUserId);

          const groupedUserData = result.data.user_current_work.reduce(
            (acc, entry) => {
              if (Number(entry.user_id) !== currentUserId) {
                if (!acc[entry.user_id]) {
                  acc[entry.user_id] = [];
                }
                acc[entry.user_id].push(entry);
              }
              return acc;
            },

            {}
          );

          const lastUserEntries = Object.values(groupedUserData).map(
            (entries) => {
              return entries.pop();
            }
          );

          setEmployeesData(lastUserEntries);

          const currentTask = result.data.user_current_work.filter(
            (entry) => Number(entry.user_id) === currentUserId
          );
          setCurrentEmployeesTaskList(currentTask);
        })
        .catch((error) => {
          ReactToastify("An error occurred. Please try again later.", "error");
          console.error("Fetch error:", error);
        });
    } else {
      ReactToastify(
        "Sorry, you are not authorized. Please log in again.",
        "error"
      );
    }
  };

  const handleEndWork = () => {
    console.log("Task ended!");

    setIsSubmitting(true);

    if (LoginToken) {
      // setLoading(true);
      const url = `${mainUrl}/user/end-current-work`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      const data = {
        current_user: newUserId,
        current_work_id: currentWorkId,
      };

      //this fetch call is used to get reviewing tasks data
      fetch(url, {
        method: "PUT",
        headers: requestOptions,
        body: JSON.stringify(data),
      })
        .then((response) => {
          getWorkMsg();
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
          } else {
            ReactToastify(result.message, "success");
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  };
  const handleEdit2 = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (LoginToken) {
      // setLoading(true);
      const url = `${mainUrl}/user/update-current-work`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      const data = {
        current_user: newUserId,
        current_work_id: currentWorkId,
        task_short_description: shortDescription,
        task_long_description: longDescription,
      };
      console.log("Short Description:", shortWorkMsg);
      console.log("Long Description:", longWorkMsg);

      //this fetch call is used to get reviewing tasks data
      fetch(url, {
        method: "PUT",
        headers: requestOptions,
        body: JSON.stringify(data),
      })
        .then((response) => {
          getWorkMsg();
          fetchUpdatedTasks();
          setShortDescription("");
          setLongDescription("");

          setHideButton(true);
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
          } else {
            ReactToastify(result.message, "success");
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  };
  const handleEdit = () => {
    // setHideButton(true)
    setHideButton((prevState) => !prevState);
    setShortDescription(shortWorkMsg);
    setLongDescription(longWorkMsg);
  };

  useEffect(() => {
    getWorkMsg();
    fetchUpdatedTasks();
  }, []);

  return (
    <>
      {/* Navbar */}
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Header */}
      <PageHeader />
      {/* Main Content */}
      <div
        className={`default__page__margin default-bg-color ${
          toggleTheme ? "dark" : ""
        } dashboard ${isOpen ? "open" : ""}`}
      >
        <div className="employee__dashboard-main-content mt-2">
          <div className="employee__dashboard-left__content">
            <div
              className={`default-section-block ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-left__content-top__div`}
            >
              <div className="text__content">
                <h5
                  className={`${
                    toggleTheme ? "dark-text-color" : "default-text-color"
                  }`}
                >
                  {message}
                  <span style={{ textTransform: "capitalize" }}>
                    {username}
                  </span>
                </h5>
                <p
                  className={`${
                    toggleTheme ? "dark-sub-text-color" : "default-text-color"
                  }`}
                >
                  {runningProjectsCount > 0
                    ? `you've been assigned with ${runningProjectsCount} projects ${
                        pendingTasks.length > 0
                          ? `& ${pendingTasks.length} tasks`
                          : ""
                      }`
                    : ""}
                </p>
                <Link
                  to="/projects/employee-projects"
                  className={`text__content-link ${toggleTheme ? "dark" : ""}`}
                >
                  View Projects
                </Link>
                <Link
                  to="/my-tasks"
                  className={`text__content-link ${toggleTheme ? "dark" : ""}`}
                >
                  View Tasks
                </Link>
              </div>
              <div className="blocks__section-content">
                <div className={`block-element ${toggleTheme ? "dark" : ""}`}>
                  <p
                    className={`block-element__title ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    All <br /> Projects
                  </p>
                  <p
                    className={`block-element__count ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    {allProjectsCount}
                  </p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/projects/employee-projects", {
                      state: {
                        location: location,
                        value: "active",
                      },
                    });
                  }}
                  className={`block-element ${toggleTheme ? "dark" : ""}`}
                >
                  <p
                    className={`block-element__title ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    Active <br /> Projects
                  </p>
                  <p
                    className={`block-element__count ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    {runningProjectsCount}
                  </p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/projects/employee-projects", {
                      state: {
                        location: location,
                        value: "completed",
                      },
                    });
                  }}
                  className={`block-element ${toggleTheme ? "dark" : ""}`}
                >
                  <p
                    className={`block-element__title ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    Completed <br /> Projects
                  </p>
                  <p
                    className={`block-element__count ${
                      toggleTheme ? "dark" : ""
                    }`}
                  >
                    {completedProjectsCount}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`default-section-block ${toggleTheme ? "dark" : ""} `}
            >
              <form
                onSubmit={handleSubmitWork}
                className="what-are-you-working-on"
              >
                <Select
                  className="short_description"
                  showSearch
                  value={shortDescription}
                  onClear={() => setShortDescription("")}
                  allowClear={true}
                  placeholder="Select an Option..."
                  optionFilterProp="children"
                  required
                  onChange={currentWorkChange}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={currentWorkOptions}
                />
                <input
                  type="text"
                  required
                  className="long_description default-input-field"
                  placeholder="Please elaborate your work..."
                  value={longDescription}
                  onChange={(e) => setLongDescription(e.target.value)}
                />

                <Button
                  type="submit"
                  className="btn default-button what-are-you-working-on-button"
                >
                  Add
                </Button>
              </form>

              {/* This Div shows the input value & need to make this field editablte */}
              <div className={`lineBreak ${toggleTheme ? "dark" : ""}`}></div>
              <div className="employee-working-on__div">
                {loading ? (
                  <TailSpin
                    height="35"
                    width="35"
                    color="#333"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    wrapperClass="loader"
                    visible={true}
                  />
                ) : errorWorkMsg !== "" ? (
                  <p
                    className={`${
                      toggleTheme ? "dark-text-color" : "default-text-color"
                    }`}
                  >
                    {errorWorkMsg}
                  </p>
                ) : (
                  <>
                    <div
                      className={`${
                        toggleTheme ? "dark-text-color" : "default-text-color"
                      } work-description`}
                    >
                      <div className="work-icon">&#10148;</div>
                      <div className="work-description-text">
                        <span className="short-work-description">
                          {shortWorkMsg}{" "}
                          {loggedInUserCurrentWork.is_task_end === "yes" ? (
                            <span style={{ color: "red" }}>(Task End)</span>
                          ) : (
                            ""
                          )}
                        </span>
                        <span className="long-work-description">
                          {longWorkMsg} -{" "}
                          {formatTime(
                            loggedInUserCurrentWork.insert_date,
                            loggedInUserCurrentWork.insert_time
                          )}{" "}
                          (
                          <span style={{ fontWeight: "600" }}>
                            {getTimeDifference(
                              `${loggedInUserCurrentWork.insert_date} ${loggedInUserCurrentWork.insert_time}`
                            )}
                          </span>
                          )
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "30px" }}>
                      {loggedInUserCurrentWork.is_task_end !== "yes" && (
                        <>
                          <Tooltip
                            id="edit-current-work-tooltip"
                            style={{
                              background: "#000",
                              opacity: ".9",
                              color: "#fff",
                            }}
                          />
                          {hideButton ? (
                            <Button
                              data-tooltip-id="edit-current-work-tooltip"
                              data-tooltip-content="Edit Your Current Work?"
                              data-tooltip-place="bottom"
                              className="btn default-button"
                              onClick={handleEdit}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                          ) : (
                            <>
                              <Tooltip
                                id="edit-current-work-tooltip"
                                style={{
                                  background: "#000",
                                  opacity: ".9",
                                  color: "#fff",
                                }}
                              />
                              <Button
                                data-tooltip-id="edit-current-work-tooltip"
                                data-tooltip-content="Update Your Current Work?"
                                data-tooltip-place="bottom"
                                className="btn default-button"
                                onClick={handleEdit2}
                              >
                                Update
                              </Button>
                            </>
                          )}
                        </>
                      )}

                      {loggedInUserCurrentWork.is_task_end !== "yes" && (
                        <>
                          <EndTaskModel endTask={handleEndWork} />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {employeesData.length > 0 ? (
                showAllEmployeesCurrentWork ? (
                  <p
                    className={`view-all-employees-current-work-p ${
                      toggleTheme ? "dark-sub-text-color" : "default-text-color"
                    }`}
                  >
                    Hide all Employees Current Work?{" "}
                    <span onClick={() => setShowAllEmployeesCurrentWork(false)}>
                      Click Here
                    </span>
                  </p>
                ) : (
                  <p
                    className={`view-all-employees-current-work-p ${
                      toggleTheme ? "dark-sub-text-color" : "default-text-color"
                    }`}
                  >
                    View all Employees Current Work?{" "}
                    <span onClick={() => setShowAllEmployeesCurrentWork(true)}>
                      Click Here
                    </span>
                  </p>
                )
              ) : (
                ""
              )}

              {/* current user */}
              <div className="mt-3">
                {currentEmployeesTaskList.length > 0 ? (
                  showCurrentEmployeeWorkList ? (
                    <p
                      className={`view-all-employees-current-work-p ${
                        toggleTheme
                          ? "dark-sub-text-color"
                          : "default-text-color"
                      }`}
                    >
                      Hide Current Work?{" "}
                      <span
                        onClick={() => setShowCurrentEmployeeWorkList(false)}
                      >
                        Click Here
                      </span>
                    </p>
                  ) : (
                    <p
                      className={`view-all-employees-current-work-p ${
                        toggleTheme
                          ? "dark-sub-text-color"
                          : "default-text-color"
                      }`}
                    >
                      Today's all my current tasks?{" "}
                      <span
                        onClick={() => setShowCurrentEmployeeWorkList(true)}
                      >
                        Click Here
                      </span>
                    </p>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="d-flex" style={{ gap: "10px" }}>
              {/* all employee  task list */}
              {showAllEmployeesCurrentWork ? (
                <div
                  className={`default-section-block ${
                    toggleTheme ? "dark" : ""
                  } animate__animated animate__fadeInDown`}
                >
                  {employeesData.length > 0 ? (
                    <div className="other-employees-currently-working">
                      <p>Other Employees Currently Working on: </p>
                      {
                        <table className="table employee-working-on-table mt-3">
                          <thead>
                            <tr
                              className={`dashboard-projects-table__heading bg-red ${
                                toggleTheme ? "dark-text-color" : ""
                              }`}
                            >
                              <th>Name</th>
                              <th>Working on...</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <TailSpin
                              height="50"
                              width="50"
                              color="#333"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{
                                position: "absolute",
                                width: "97%",
                                height: "15vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              wrapperClass="loader"
                              visible={true}
                            />
                          ) : (
                            <tbody>
                              {employeesData.map((employee) => {
                                const {
                                  id,
                                  insert_date,
                                  task_long_description,
                                  task_short_description,
                                  username,
                                  user_id,end_time_is,task_end_on,
                                  insert_time,
                                } = employee;
                                return (
                                  <tr
                                    className={`dashboard-projects-table__values ${
                                      toggleTheme
                                        ? "dark-sub-text-color"
                                        : "default-text-color"
                                    }`}
                                    key={id}
                                  >
                                    <td>{username}</td>
                                    <td className="employee-description">
                                      <span
                                        className={`employee-short__description ${
                                          toggleTheme
                                            ? "dark-sub-text-color"
                                            : "default-text-color"
                                        }`}
                                        style={{
                                          boxShadow: `${
                                            task_short_description ===
                                            "(I'm Free) Learning / Practicing"
                                              ? toggleTheme
                                                ? "-8px 0px 0px 0px #b1bac41f"
                                                : "-8px 0px 0px 0px #e8eaef"
                                              : ""
                                          }`,
                                          width: "100%",
                                          background: `${
                                            task_short_description ===
                                            "(I'm Free) Learning / Practicing"
                                              ? toggleTheme
                                                ? "#b1bac41f"
                                                : "#e8eaef"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        {task_short_description}
                                      </span>
                                      <span
                                        className={`${
                                          toggleTheme
                                            ? "dark-sub-text-color"
                                            : "default-text-color"
                                        }`}
                                      >
                                        {task_long_description} -{" "}
                                        {formatTime(insert_date, insert_time)}
                                        {task_end_on == null ? (
                                          ""
                                        ) : (
                                          <span style={{ fontWeight: "600" }}>
                                            ({formatTotalWorkTime(
                                              end_time_is || ""
                                            )})
                                          </span>
                                        )}
                                         {/* (
                                        <span style={{ fontWeight: "600" }}>
                                          {getTimeDifference(
                                            `${insert_date} ${insert_time}`
                                          )}
                                        </span>
                                        ) */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* current user task list */}
              {showCurrentEmployeeWorkList ? (
                <div
                  className={`default-section-block ${
                    toggleTheme ? "dark" : ""
                  } animate__animated animate__fadeInDown`}
                >
                  {currentEmployeesTaskList.length > 0 ? (
                    <div className="other-employees-currently-working">
                      <p>My Work: </p>
                      {
                        <table className="table employee-working-on-table mt-3">
                          <thead>
                            <tr
                              className={`dashboard-projects-table__heading bg-red ${
                                toggleTheme ? "dark-text-color" : ""
                              }`}
                            >
                              {/* <th>Name</th> */}

                              <th>Today's Worked on...</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <TailSpin
                              height="50"
                              width="50"
                              color="#333"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{
                                position: "absolute",
                                width: "97%",
                                height: "15vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              wrapperClass="loader"
                              visible={true}
                            />
                          ) : (
                            <tbody>
                              {currentEmployeesTaskList.map((employee) => {
                                const {
                                  id,
                                  insert_date,
                                  task_long_description,
                                  task_short_description,
                                  username,
                                  task_end_on,
                                  end_time_is,
                                  user_id,
                                  insert_time,
                                } = employee;
                                return (
                                  <tr
                                    className={`dashboard-projects-table__values ${
                                      toggleTheme
                                        ? "dark-sub-text-color"
                                        : "default-text-color"
                                    }`}
                                    key={id}
                                  >
                                    {/* <td>{username}</td> */}
                                    <td className="employee-description">
                                      <span
                                        className={`employee-short__description ${
                                          toggleTheme
                                            ? "dark-sub-text-color"
                                            : "default-text-color"
                                        }`}
                                        style={{
                                          boxShadow: `${
                                            task_short_description ===
                                            "(I'm Free) Learning / Practicing"
                                              ? toggleTheme
                                                ? "-8px 0px 0px 0px #b1bac41f"
                                                : "-8px 0px 0px 0px #e8eaef"
                                              : ""
                                          }`,
                                          width: "100%",
                                          background: `${
                                            task_short_description ===
                                            "(I'm Free) Learning / Practicing"
                                              ? toggleTheme
                                                ? "#b1bac41f"
                                                : "#e8eaef"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        {task_short_description}
                                      </span>
                                      <span
                                        className={`${
                                          toggleTheme
                                            ? "dark-sub-text-color"
                                            : "default-text-color"
                                        }`}
                                      >
                                        {task_long_description} -{" "}
                                        {formatTime(insert_date, insert_time)}
                                        {task_end_on == null ? (
                                          ""
                                        ) : (
                                          <span style={{ fontWeight: "600" }}>
                                            ({formatTotalWorkTime(
                                              end_time_is || ""
                                            )})
                                          </span>
                                        )}
                                        
                                        {/* {console.log(formatTotalWorkTime(end_time_is),"formatTotalWorkTimeformatTotalWorkTime")} */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`default-section-block ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-left__content-bottom__div`}
            >
              <div className="barChart">
                <EmployeeBarChart />
              </div>
            </div>
          </div>
          <div className="employee__dashboard-right__content">
            <div
              className={`default-section-block ${
                toggleTheme ? "dark" : ""
              } employee__dashboard-right__content-top__div`}
            >
              <div className="employee-doughnut-chart">
                <EmployeeDoughnutChart />
              </div>
            </div>

            {/* Todo list */}
            <DashboardTodoList />

            {/* Suggestion Box */}
            {/* <DashboardSuggestionBox /> */}

            {/* Quick Links */}
            <DashboardQuickLinks />
          </div>
        </div>
      </div>
      {/* Footer */}
      <PageFooter />
    </>
  );
};
