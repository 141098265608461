import React, { useContext, useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";

import { ContextUser } from "../../../Context/UserContext";
import { ContextTheme } from "../../../Context/ThemeContext";
import { formatTime } from "../../Utility/formatTime";
import { getTimeDifference } from "../../Utility/calculateTime";
import ReactToastify from "../../Utility/ReactToastify";
import { dateInYYMMDD } from "../../Utility/formatDateInWords";
import EmployeesCurrentWorkModal from "./EmployeesCurrentWorkModal";
import {formatTotalWorkTime} from "../../Utility/formatTotalWorkTime"

export const DashboardEmployeesCurrentWork = () => {
  const { toggleTheme } = useContext(ContextTheme);
  const { mainUrl } = useContext(ContextUser);

  // states
  const [loading, setLoading] = useState(false);
  const [employeesCurrentWork, setEmployeesCurrentWork] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [currentWorkData, setCurrentWorkData] = useState([]);

  const LoginToken = localStorage.getItem("LoginToken");
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");
  const userId = localStorage.getItem("userId"); // the logged in user id
  const newUserId = userId.replace(/['"]+/g, "");

  // API for getting what employees are working on
  useEffect(() => {
    if (LoginToken) {
      setLoading(() => true);
      const url = `${mainUrl}/user/get-current-work-by-employee/${newUserId}`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get reviewing tasks data
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
          } else {
            if (result.data.user_current_work.length > 0) {
              const formatEmployeesData = result.data.user_current_work.reduce(
                (acc, curr) => {
                  if (acc.find((a) => a.id === curr.user_id)) {
                    acc = acc.map((a) =>
                      a.id === curr.user_id
                        ? {
                            ...a,
                            data: [...a.data, { ...curr }],
                          }
                        : a
                    );
                    return acc;
                  } else {
                    acc = [
                      ...acc,
                      {
                        id: curr.user_id,
                        username: curr.username,
                        data: [{ ...curr }],
                      },
                    ];
                    return acc;
                  }
                },
                []
              );
              setEmployeesCurrentWork(formatEmployeesData);
            }
          }
          setLoading(() => false);
        })
        .catch((error) => {
          setLoading(() => false);
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  }, []);

  // API for getting all the Active Employees
  useEffect(() => {
    setLoading(true);

    if (LoginToken) {
      const url = `${mainUrl}/user`;

      const requestOptions = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: newBearer,
      };

      //this fetch call is used to get all users data
      fetch(url, {
        method: "GET",
        headers: requestOptions,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            ReactToastify(result.message, "error");
            setLoading(false);
          } else {
            const userData = result.data.filter(
              ({ roles, status }) => roles === "employee" && status === "active"
            );
            setEmployees(userData);
            setLoading(false);
          }
        })
        .catch((error) => {
          ReactToastify(error, "error");
        });
    } else {
      ReactToastify(
        "Sorry you are not authorised, please login again",
        "error"
      );
    }
  }, []);

  const sortByDate = (dataset) => {
    const sortedArray = dataset.sort(
      (a, b) =>
        new Date(b.insert_date + " " + b.insert_time) -
        new Date(a.insert_date + " " + a.insert_time)
    );

    const formattedArray = sortedArray.map((item) => {
      const time = new Date(
        item.insert_date + " " + item.insert_time
      ).toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" });
      return { ...item, formattedTime: time };
    });

    return formattedArray;
  };

  useEffect(() => {
    const data = employees.map((user) => {
      const userWorkData = employeesCurrentWork.find(
        ({ id }) => id === user.id
      );
      return {
        id: user.id,
        username: user.username,
        currentWork: userWorkData ? sortByDate(userWorkData.data) : null,
      };
    });

    setCurrentWorkData(data);
  }, [employeesCurrentWork, employees]);

  // helper function for checking if employee has entered present date current work or not
  const insertedPresentDateCurrentWork = (dataset) => {
    // if not current work available exit from this func
    if (!dataset) return false;

    const presentDateCurrentWorkAvailable = (data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].insert_date === dateInYYMMDD(new Date())) return true;
      }
      return false;
    };

    // if present date current-work not available then exit from this func
    if (!presentDateCurrentWorkAvailable(dataset)) return false;

    return true;
  };

  const NoCurrentWork = ({ username }) => {
    return (
      <span
        className={`w-100 employee-short__description ${
          toggleTheme ? "dark-sub-text-color" : "default-text-color"
        }`}
        style={{
          boxShadow: `${
            toggleTheme
              ? "-8px 0px 0px 0px #930f0f"
              : "-8px 0px 0px 0px #f4cccc"
          }`,
          background: `${toggleTheme ? "#930f0f" : "#f4cccc"}`,
        }}
      >
        {username} has not updated their today's current work!
      </span>
    );
  };

  const CurrentWork = ({
    task_short_description,
    task_long_description,
    insert_date,
    insert_time,task_end_on,end_time_is
  }) => {
    const bool = task_short_description === "In House Project";
    return (
      <>
        <span
          className={`employee-short__description ${
            toggleTheme ? "dark-sub-text-color" : "default-text-color"
          }`}
          style={{
            boxShadow: `${
              bool
                ? toggleTheme
                  ? "-8px 0px 0px 0px #b1bac41f"
                  : "-8px 0px 0px 0px #faa31f"
                : ""
            }`,
            background: `${
              bool ? (toggleTheme ? "#b1bac41f" : "#faa31f") : ""
            }`,
          }}
        >
          {task_short_description} {toggleTheme}
        </span>
        <span
          className={`${
            toggleTheme ? "dark-sub-text-color" : "default-text-color"
          }`}
        >
          {task_long_description} - {formatTime(insert_date, insert_time)} 
          {task_end_on == null ? (
                                          ""
                                        ) : (
                                          <span style={{ fontWeight: "600" }}>
                                            ({formatTotalWorkTime(
                                              end_time_is || ""
                                            )})
                                          </span>
                                        )}
          {/* (
          <span style={{ fontWeight: "600" }}>
            {getTimeDifference(`${insert_date} ${insert_time}`)}
          </span>
          ) */}
        </span>
      </>
    );
  };

  return (
    <div
      className={`default-section-block dashboard__employees-working-on ${
        toggleTheme ? "dark" : ""
      }`}
    >
      <>
        {currentWorkData?.length > 0 ? (
          <table className="table employee-working-on-table">
            <thead>
              <tr
                className={`dashboard-projects-table__heading bg-red ${
                  toggleTheme ? "dark-text-color" : ""
                }`}
              >
                <th>Name</th>
                <th>Working on...</th>
              </tr>
            </thead>
            {loading ? (
              <TailSpin
                height="50"
                width="50"
                color="#333"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  position: "absolute",
                  width: "97%",
                  height: "15vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass="loader"
                visible={true}
              />
            ) : (
              <tbody>
                {currentWorkData.map((employee) => {
                  const { id, username, currentWork } = employee;
                  return (
                    <tr
                      className={`dashboard-projects-table__values ${
                        toggleTheme
                          ? "dark-sub-text-color"
                          : "default-text-color"
                      }`}
                      key={id}
                    >
                      <td>{username}</td>
                      <td className="employee-description">
                        <span className="w-100 employee-current-work-wrapper">
                          <span className="width-90 employee-current-work">
                            {/* checking if user has not filled present date current work */}
                            {insertedPresentDateCurrentWork(currentWork) ? (
                              <CurrentWork
                                task_short_description={
                                  currentWork[0]?.task_short_description
                                }
                                task_long_description={
                                  currentWork[0]?.task_long_description
                                }
                                insert_date={currentWork[0]?.insert_date}
                                insert_time={currentWork[0]?.insert_time}
                              />
                            ) : (
                              <NoCurrentWork username={username} />
                            )}
                          </span>
                          <span className="width-10 employee-other-current-work">
                            {insertedPresentDateCurrentWork(currentWork) &&
                              currentWork?.slice(1)?.length >= 1 && (
                                <EmployeesCurrentWorkModal
                                  employee={employee}
                                />
                              )}
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        ) : (
          <p
            className={`${
              toggleTheme ? "dark-sub-text-color" : "default-text-color"
            }`}
          >
            Please request your staff to input their current work 😟
          </p>
        )}
      </>
    </div>
  );
};
