export const formatTotalWorkTime = (timeStr) => {
    const parts = timeStr.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
  
    let result = "";
    if (hours > 0) {
        result += `${hours} hours${hours > 1 ? '' : ''} `;
    }
    if (minutes > 0 || hours > 0) { 
        result += `${minutes} mins${minutes > 1 ? '' : ''} `;
    }
    if (seconds > 0 || result === "") {
        result += `${seconds} second${seconds > 1 ? '' : ''}`;
    }
    return result.trim();
  
  }
