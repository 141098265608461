export const employeeJobRoleOptions = [
  { label: "Web Designer", value: "Web Designer" },
  { label: "Frontend Developer", value: "Frontend Developer" },
  { label: "Backend Developer", value: "Backend Developer" },
  { label: "Graphic Designer", value: "Graphic Designer" },
  { label: "Content Writer", value: "Content Writer" },
  { label: "SEO Specialist", value: "SEO Specialist" },
  { label: "PPC Specialist", value: "PPC Specialist" },
  { label: "Research Development", value: "Research Development" },
  { label: "Documentation", value: "Documentation" },
];
