import React, { useContext } from "react";
import { Tooltip } from "react-tooltip";

import { ContextTheme } from "../../../Context/ThemeContext";

export const DashboardSuggestionBox = () => {
  const { toggleTheme } = useContext(ContextTheme);

  const userName = localStorage.getItem("userName").replace(/['"]+/g, "");

  return (
    <div
      className={`user-todo-list default-section-block ${
        toggleTheme ? "dark" : ""
      }`}
    >
      <h5
        className={`projects__summary__header ${
          toggleTheme ? "dark" : ""
        } employee__dashboard-title`}
      >
        Have any suggestions?
      </h5>
      <div className="todo-list-header">
        <input type="text" autoComplete="off" className="default__input" />

        <button class="btn-cssbuttons">
          <span>
            <svg
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1024 1024"
              class="icon"
            >
              <path
                fill="#ffffff"
                d="M767.99994 585.142857q75.995429 0 129.462857 53.394286t53.394286 129.462857-53.394286 129.462857-129.462857 53.394286-129.462857-53.394286-53.394286-129.462857q0-6.875429 1.170286-19.456l-205.677714-102.838857q-52.589714 49.152-124.562286 49.152-75.995429 0-129.462857-53.394286t-53.394286-129.462857 53.394286-129.462857 129.462857-53.394286q71.972571 0 124.562286 49.152l205.677714-102.838857q-1.170286-12.580571-1.170286-19.456 0-75.995429 53.394286-129.462857t129.462857-53.394286 129.462857 53.394286 53.394286 129.462857-53.394286 129.462857-129.462857 53.394286q-71.972571 0-124.562286-49.152l-205.677714 102.838857q1.170286 12.580571 1.170286 19.456t-1.170286 19.456l205.677714 102.838857q52.589714-49.152 124.562286-49.152z"
              ></path>
            </svg>
          </span>
          <ul>
            <li>
              <Tooltip
                id="anonymously-tooltip"
                style={{
                  background: "#000",
                  opacity: "1",
                  color: "#fff",
                }}
              />
              <span
                data-tooltip-id="anonymously-tooltip"
                data-tooltip-content={`Share as Anonymously`}
                data-tooltip-place="top"
              >
                🤫
              </span>
            </li>
            <li>
              <Tooltip
                id="yourself-tooltip"
                style={{
                  background: "#000",
                  opacity: "1",
                  color: "#fff",
                }}
              />
              <span
                data-tooltip-id="anonymously-tooltip"
                data-tooltip-content={`Share as ${userName}`}
                data-tooltip-place="top"
              >
                😎
              </span>
            </li>
            <li>
              <Tooltip
                id="discard-tooltip"
                style={{
                  background: "#000",
                  opacity: "1",
                  color: "#fff",
                }}
              />
              <span
                data-tooltip-id="discard-tooltip"
                data-tooltip-content={`Discard`}
                data-tooltip-place="top"
              >
                ❌
              </span>
            </li>
          </ul>
        </button>
      </div>
    </div>
  );
};
