import React, { useContext } from "react";

import { PageSizeDropdown } from "./PageSizeDropdown";
import { ContextTheme } from "../../Context/ThemeContext";

export const PaginationElements = ({
  pageIndex,
  pageOptions,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  setPageSize,
  pageSize,
}) => {
  const { toggleTheme } = useContext(ContextTheme);

  return (
    <div>
      <span
        className={`${
          toggleTheme ? "dark-sub-text-color" : "default-text-color"
        }`}
      >
        <strong>
          Page {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <button
        disabled={!canPreviousPage}
        className=" prev-button"
        onClick={() => previousPage()}
      >
        👈 prev
      </button>
      <button
        disabled={!canNextPage}
        className=" next-button"
        onClick={() => nextPage()}
      >
        next 👉
      </button>
      <PageSizeDropdown pageSize={pageSize} setPageSize={setPageSize} />
    </div>
  );
};
