import React from "react";

import "../../stylesheet/Projects/Projects.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../stylesheet/App.css";

import Navbar from "../Navbar/Navbar";
import { PageHeader } from "../Utility/PageHeader";

export const Projects = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <PageHeader />
    </>
  );
};
