// libraries
import React, { useMemo, useContext } from "react";
import { CommonGlobalFilter } from "../../../GlobalFilter/CommonGlobalFilter";
import { TailSpin } from "react-loader-spinner";

// components
import { ProjectStage } from "../../ForAdmin/ToAddAProject/ProjectStage";
import ProjectUpdateModal from "../ProjectUpdateModal";
import ProjectCommentModal from "../ProjectCommentModal";
import ActiveProjectDescriptionModal from "./ActiveProjectDescriptionModal";
import { ContextTheme } from "../../../../Context/ThemeContext";
import { formatDateTime } from "../../../Utility/formatDateTime";
import { PaginationElements } from "../../../Utility/PaginationElements";

// using react table
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

export const ActiveProjects = ({
  onlyActiveProjects,
  loading,
  setLoading,
  setProjectUpdated,
}) => {
  const { toggleTheme } = useContext(ContextTheme);

  const Columns = [
    {
      Header: "Project Id.",
      Cell: ({ row }) =>
        row.original.project_no !== "" ? row.original.project_no : "N/A",
    },
    {
      Header: "Project Name",
      accessor: "Project_name",
    },
    {
      Header: "Assigned Date",
      accessor: "assign_date",
      Cell: (row) => formatDateTime(row.row.original.assign_date),
    },
    {
      Header: "Project Status",
      accessor: "project_status",
    },
    {
      Header: "Project Stage",
      accessor: "project_stage",
      Cell: ({ row }) => {
        return (
          <ProjectStage
            stage={row.original.project_stage}
            status={row.original.project_status}
          />
        );
      },
    },
    {
      Header: "Description",
      Cell: ({ row }) => {
        const data = row.original;
        return <ActiveProjectDescriptionModal projectDescriptionData={data} />;
      },
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <ProjectUpdateModal
          projectData={row.original}
          setProjectUpdated={setProjectUpdated}
        />
      ),
    },
    {
      Header: "Update",
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <ProjectCommentModal
            commentModalData={data}
            setLoading={setLoading}
          />
        );
      },
    },
  ];

  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => onlyActiveProjects, [onlyActiveProjects]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div>
      <div>
        <div className="my-projects mb-3">
          <h4
            className={`${
              toggleTheme ? "dark-text-color" : "default-text-color"
            }`}
          >
            Active Projects ({onlyActiveProjects.length})
          </h4>
          <div className="default__table__section">
            {loading ? (
              <TailSpin
                height="80"
                width="80"
                color="#333"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  position: "absolute",
                  width: "97%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass="loader"
                visible={true}
              />
            ) : onlyActiveProjects.length >= 1 ? (
              <div>
                <CommonGlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
                <table
                  {...getTableProps()}
                  className="table text-center default__table__content"
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className={`default-projects-table__heading ${
                          toggleTheme ? "dark-text-color" : "default-text-color"
                        }`}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              className="default-projects-table__heading-headers"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              <span>
                                <span>{column.render("Header")}</span>
                                <span className="table-heading-span-styling-arrow">
                                  {column.Header === "Description" ||
                                  column.Header === "Edit" ||
                                  column.Header === "Update" ||
                                  column.Header === "Project Id." ? (
                                    ""
                                  ) : column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <span>↓</span>
                                    ) : (
                                      <span>↑</span>
                                    )
                                  ) : (
                                    <span>⇅</span>
                                  )}
                                </span>
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          className={`default-projects-table__values ${
                            toggleTheme
                              ? "dark-sub-text-color"
                              : "default-text-color"
                          }`}
                          {...row.getRowProps()}
                          key={row.original.Project_id}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="active-projects-table-body-rows-td"
                                style={{
                                  color: `${
                                    cell.column.Header === "Project Status" &&
                                    cell.value === "Running"
                                      ? "#318CE7"
                                      : cell.column.Header ===
                                          "Project Status" &&
                                        cell.value === "Hold"
                                      ? `${toggleTheme ? "#FF3131" : "red"}`
                                      : cell.column.Header ===
                                          "Project Status" &&
                                        cell.value === "Completed"
                                      ? `${toggleTheme ? "#50C878" : "#097969"}`
                                      : ""
                                  }`,
                                  fontWeight: `${
                                    cell.column.Header === "Project Status"
                                      ? "600"
                                      : ""
                                  }`,
                                }}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {onlyActiveProjects && onlyActiveProjects.length > 10 ? (
                  <PaginationElements
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div
                className={`no-project-msg ${
                  toggleTheme ? "dark-sub-text-color" : "default-text-color"
                }`}
              >
                {/* {message} */}
                No Projects Found 🙁
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
