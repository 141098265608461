// libraries
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// component
import { ContextUser } from "../Context/UserContext";

export default function CheckUserExists() {
  const { mainUrl } = useContext(ContextUser);
  const navigate = useNavigate();

  const LoginToken = localStorage.getItem("LoginToken");
  const bearer = "Bearer " + LoginToken;
  const newBearer = bearer.replace(/['"]+/g, "");

  const userId = localStorage.getItem("userId"); // logged in userId
  const newUserId = userId.replace(/['"]+/g, "");

  // if any character in localstorage is changed then logout the user
  // useEffect(() => {
  //   window.addEventListener("storage", function (e) {
  //     console.log(e);
  //     if (
  //       e.key === "LoginToken" ||
  //       e.key === "userAuthority" ||
  //       e.key === "userId" ||
  //       e.key === "userRole"
  //     ) {
  //       localStorage.clear();
  //       const tokenTampered = "Something went wrong, please login again!";
  //       localStorage.setItem("tokenTampered", tokenTampered);
  //       navigate("/");
  //       console.log("more inside");
  //     }
  //   });
  // }, []);

  // checking if user exists or not, if not then logout the user
  useEffect(() => {
    // this API checks if user exists in database or not
    const url = `${mainUrl}/user/${newUserId}`;
    const requestOptions = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: newBearer,
    };

    fetch(url, {
      method: "GET",
      headers: requestOptions,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // if the login token is expired
        // result.status === 401  for live after build
        // result.code === 500 || result.error  for localhost & vercel
        if (result.code === 500 || result.error) {
          setTimeout(() => {
            // localStorage.clear();
            localStorage.removeItem("LoginToken");
            localStorage.removeItem("userRole");
            localStorage.removeItem("userName");
            localStorage.removeItem("userId");
            const tokenExpired = result.message;
            localStorage.setItem("tokenExpired", tokenExpired);
            alert("Your Token is Expired, Please login again!");
            // navigate("/");
            window.location.reload();
          }, 0);
        }

        // result.status === 403
        // if (result.status === 403) {
        //   setTimeout(() => {
        //     // localStorage.clear();
        //     localStorage.removeItem("LoginToken");
        //     localStorage.removeItem("userRole");
        //     localStorage.removeItem("userName");
        //     localStorage.removeItem("userId");
        //     const userSuspended = result.message;
        //     localStorage.setItem("userSuspended", userSuspended);
        //     alert(
        //       "Your Account is Suspended, Please contact your Administrator!"
        //     );
        //     // navigate("/");
        //     window.location.reload();
        //   }, 0);
        // }

        // if user is deleted from database then also logout the user
        // result.status === 404 for Live server
        // result.error for vercel & localhost
        // if (result.error) {
        //   setTimeout(() => {
        //     // localStorage.clear();
        //     localStorage.removeItem("LoginToken");
        //     localStorage.removeItem("userRole");
        //     localStorage.removeItem("userName");
        //     localStorage.removeItem("userId");
        //     const userDeleted =
        //       "You're no longer a user 😟, please register yourself again!";
        //     localStorage.setItem("userDeleted", userDeleted);
        //     alert(
        //       "Your Account is Deleted, Please contact your Administrator!"
        //     );
        //     // navigate("/");
        //     window.location.reload();
        //   }, 0);
        // }
      });
  }, []);
}
